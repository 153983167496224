<template>
    <div id="app">
        <div id="tip" v-show="androidAndWx"></div>
        <svg-icon icon-class="success"
            style="width: 100px;height: 100px;margin-top: 200px;margin-bottom: 20px;"></svg-icon>
        <div style="color:white;font-size: small;margin-bottom: 20px;">{{ content1 }}</div>
        <el-checkbox-group v-model="specificationArr" style="margin-bottom: 20px;">
            <el-checkbox style="color: white;" :label="content2"></el-checkbox>
            <el-checkbox style="color: white;" :label="content3"></el-checkbox>
        </el-checkbox-group>
        <el-button @click="downloadSelected" type="primary">{{ content4 }}</el-button>
    </div>
</template>

<script>
export default {
    data() {
        return {
            androidAndWx: false,
            specificationArr: [],
            SdUrl: null,
            HdUrl: null,
            lang: null,
            content1: null,
            content2: null,
            content3: null,
            content4: null
        }
    },
    methods: {
        getLanguage() {
            var language = null;
            var lang = null;
            if (navigator.appName == 'Netscape') {
                language = navigator.language;
            }
            else {
                language = navigator.browserLanguage;
            }
            if (language.indexOf('zh') > -1) {
                lang = "zh"
            } else if (language.indexOf('ja') > -1) {
                lang = "ja"
            } else {
                lang = "en"
            }
            switch (lang) {
                case 'en':
                    this.content1 = 'Select video specifications for download'
                    this.content2 = '3840 x 2160 (HD)'
                    this.content3 = '3840 x 2160 (SD)'
                    this.content4 = 'download video'
                    break;
                case 'ja':
                    this.content1 = 'ダウンロードするビデオ仕様を選択'
                    this.content2 = '3840 x 2160 (HD)'
                    this.content3 = '3840 x 2160 (SD)'
                    this.content4 = 'ビデオのダウンロード'
                    break;
                case 'zh':
                    this.content1 = '选择视频规格进行下载'
                    this.content2 = '3840 x 2160 (高清)'
                    this.content3 = '3840 x 2160 (标清)'
                    this.content4 = '下载视频'
                    break;
            }
        },
        iswx() {
            var ua = navigator.userAgent.toLowerCase();
            var isWeixin = ua.indexOf('micromessenger') != -1;
            if (isWeixin) {
                return true;
            } else {
                return false;
            }
        },
        downloadSelected() {
            for (let index = 0; index < this.specificationArr.length; index++) {
                const element = this.specificationArr[index];
                if (element == '3840 x 2160 (高清)') {
                    this.downloadAfterLogin(this.HdUrl, "video_Hd.mp4")
                } else {
                    this.downloadAfterLogin(this.SdUrl, "video_Sd.mp4")
                }
            }
        },
        downloadAfterLogin(url, name) {
            var x = new XMLHttpRequest()
            x.open('GET', url, true)
            x.responseType = 'blob'
            x.onload = function () {
                const url = window.URL.createObjectURL(x.response)
                const eleLink = document.createElement('a')
                eleLink.href = url
                eleLink.download = name
                eleLink.style.display = 'none'
                document.body.appendChild(eleLink)
                eleLink.click()
                document.body.removeChild(eleLink)
            }
            x.send()
        }

    },
    mounted() {
        this.getLanguage()
        var isWx = this.iswx()
        if (isWx) {
            // 提示跳转页面
            this.androidAndWx = true
        } else {
            this.HdUrl = decodeURIComponent(this.$route.params.url)
            var splitArr = this.HdUrl.split("/")
            this.SdUrl = this.HdUrl.replace(splitArr[splitArr.length - 1], 'videosSD/' + splitArr[splitArr.length - 1])
            // this.downloadAfterLogin()
        }

    }
}
</script>

<style scoped>
#app {
    width: 100%;
    position: absolute;
    height: 100%;
    left: 0;
    top: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    background-color: #F8F8F8;
    align-items: center;
    background: url("../assets/background.png") center center no-repeat;
    /* overflow: hidden; */
}

#tip {
    width: 100%;
    position: absolute;
    height: 100%;
    left: 0;
    top: 0;
    padding: 0;
    background: url("../assets/android.png") center center / contain no-repeat;
    z-index: 10;
    background-color: rgb(64, 62, 62);
    /* opacity: 0.9; */
}
</style>