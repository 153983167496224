import Vue from 'vue'
import VueRouter from 'vue-router'
import indexCom from '../views/indexCom.vue'
import contentCom from '../views/contentCom.vue'
import VideoListCom from '../views/VideoListCom.vue'
import MineInfo from '../views/MineInfo.vue'
import UserRegistration from '../views/UserRegistration.vue'
import UserLogin from '../views/UserLogin.vue'
import InfoCom from '../views/InfoCom.vue'
import PayCom from '../views/getPayCode.vue'
import PaySuccessCom from '../views/successNotify.vue'
import DownloadForFree from '../views/DownloadForFree.vue'
import DownloadPage from '../views/downloadPage.vue'
Vue.use(VueRouter)

const originalPush = VueRouter.prototype.push
//修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
//解决vue路由重复导航错误
//获取原型对象上的push函数

const routes = [
  {
    path: '/',
    redirect: '/contentCom'
  },
  {
    path: '/contentCom',
    name: 'contentCom',
    component: contentCom,
    children: [
      {
        path: '/',
        redirect: '/contentCom/indexCom'
      },
      {
        path: 'indexCom',
        name: 'indexCom',
        component: indexCom
      },
      {
        path: 'MineInfo',
        name: 'MineInfo',
        component: MineInfo
      },
      {
        path: 'InfoCom',
        name: 'InfoCom',
        component: InfoCom
      }
    ]
  },
  {
    path: '/VideoListCom:content',
    name: 'VideoListCom',
    component: VideoListCom,
    meta: {
      keepAlive: true
    }
  },
  {
    path: '/UserRegistration',
    name: 'UserRegistration',
    component: UserRegistration
  },
  {
    path: '/UserLogin',
    name: 'UserLogin',
    component: UserLogin
  },
  {
    path: '/PayCom',
    name: 'PayCom',
    component: PayCom
  },
  {
    path: '/PaySuccessCom:noneStr',
    name: 'PaySuccessCom',
    component: PaySuccessCom
  },
  {
    path: '/DownloadForFree:url',
    name: 'DownloadForFree',
    component: DownloadForFree
  },
  {
    path: '/DownloadPage',
    name: 'DownloadPage',
    component: DownloadPage
  }
]

const router = new VueRouter({
  // mode: 'history',
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  // console.log("源头路由：" + from.path);
  // console.log("目的路由：" + to.path);
  if (from.path.indexOf("VideoListCom") != -1) {
    if (to.path.indexOf("indexCom") != -1) {
      from.meta.keepAlive = false;  // 让 列表页 缓存，即不刷新
      console.log("源头刷新");
    } else {
      from.meta.keepAlive = true;  // 让 列表页 缓存，即不刷新
      console.log("源头不刷新");
    }

  }
  // else if (from.path.indexOf("indexCom") != -1 && to.path.indexOf("VideoListCom") != -1){
  //   to.meta.keepAlive = false; 
  //   console.log("目的页面刷新");
  // }
  next();
})

export default router
