<template>
    <div class="app">
        <div class="backClass" style="flex: 2;width: 100%;background-color: aqua;display: flex;align-items: center;">
            <img :src="(userInfo.icon == null ? 'https://ts1.cn.mm.bing.net/th?id=OIP-C.Y-NsSYXU2YBgwjIfmL879AHaHa&w=250&h=250&c=8&rs=1&qlt=90&o=6&dpr=1.5&pid=3.1&rm=2' : userInfo.icon)"
                style="background-color: white;width: 100px;height: 100px;border-radius: 50%;margin-left: 10px;" alt="">
            <div
                style="width: 50%;height: 50%;color:white;margin-left: 10px;display:flex ;flex-direction: column;justify-content: space-around;">
                <div v-if="isLogin == true">{{ userInfo.username }}</div>
                <div v-if="isLogin == true">ID:{{ userInfo.id }}</div>
                <el-button v-if="isLogin == false" type="primary"
                    style="width: 80px;display: flex;justify-content: center;align-items: center;" @click="gotoLogin">{{
                content1 }}</el-button>
            </div>

        </div>
        <div style="flex: 8;width: 100%;">
            <div style="display: flex;align-items: center;background-color: white;border-bottom: solid 1px #EDF0F5;"
                @click="showVideoList = !showVideoList">
                <svg-icon icon-class="video" style="margin: 10px;"></svg-icon>
                <div style="color: #4A80F3;">{{ content2 }}</div>
            </div>
            <el-collapse-transition>
                <div v-if="showVideoList"
                    style="width: 100%;display: flex;flex-direction: column;align-items: center;background-color: white;border-bottom: solid 1px #EDF0F5;">
                    <div v-for="item, index in videoArr" :key="index"
                        style="width: 90%;display: flex;justify-content: space-between;align-items: center;margin: 10px 0;">

                        <video controls="controls" style="width: 40%;height: 100px;background-color: black;"
                            controlslist="nodownload" :poster="item.posterUrl">
                            <source :src="item.videoUrl" type="video/mp4">
                        </video>
                        <el-button type="primary" @click="downLoadMineVideo(item.videoUrl)">{{ content3 }}</el-button>
                    </div>
                </div>
            </el-collapse-transition>
            <div style="display: flex;align-items: center;background-color: white;border-bottom: solid 1px #EDF0F5;">
                <svg-icon icon-class="star" style="margin: 10px;"></svg-icon>
                <div style="color: #4A80F3;">{{ content4 }}</div>
            </div>
            <el-collapse-transition>
                <div v-if="showStarList" style="width: 100%;display: flex;flex-direction: column;align-items: center;">
                    <div v-for="item, index in starList" :key="index"
                        style="width: 90%;display: flex;justify-content: space-between;align-items: center;margin: 10px 0;">


                    </div>
                </div>
            </el-collapse-transition>
            <el-button v-if="isLogin == true" @click="loginOut" style="width: 100%;margin-top: 10px;">{{ content5
                }}</el-button>
        </div>
    </div>
</template>

<script>
import request from "../axios/request";
export default {
    data() {
        return {
            content1: null,
            content2: null,
            content3: null,
            content4: null,
            content5: null,
            userInfo: {
                icon: null,
                username: null,
                id: null
            },
            activeName: '1',
            videoArr: [],
            funcBar: [
                {
                    title: "我的视频",
                    icon: 'video'
                },
                {
                    title: "我的收藏",
                    icon: 'star'
                }
            ],
            showVideoList: false,
            starList: [],
            showStarList: false,
            isLogin: false,
            lang: null
        }
    },
    methods: {
        getLanguage() {
            var language = null;
            var lang = null;
            if (navigator.appName == 'Netscape') {
                language = navigator.language;
            }
            else {
                language = navigator.browserLanguage;
            }
            if (language.indexOf('zh') > -1) {
                lang = "zh"
            } else if (language.indexOf('ja') > -1) {
                lang = "ja"
            } else {
                lang = "en"
            }
            switch (lang) {
                case 'en':
                    this.content1 = "login"
                    this.content2 = "My video"
                    this.content3 = "download video"
                    this.content4 = "My Collection"
                    this.content5 = "logout"
                    break;
                case 'ja':
                    this.content1 = "ログイン"
                    this.content2 = "動画リスト"
                    this.content3 = "ビデオのダウンロード"
                    this.content4 = "私のコレクション"
                    this.content5 = "ログオンの終了"
                    break;
                case 'zh':
                    this.content1 = "登录"
                    this.content2 = "我的视频"
                    this.content3 = "下载视频"
                    this.content4 = "我的收藏"
                    this.content5 = "退出登录"
                    break;
            }
        },
        downLoadMineVideo(item) {
            sessionStorage.setItem("videoUrl", item)
            this.$router.push({
                name: 'DownloadPage'
            })
        },
        async loginOut() {
            var resOfLogout = await request({
                url: "/api/user/logout",
                method: "PUT"
            })
            if (resOfLogout.data.status == 200) {
                localStorage.removeItem("token")
                this.isLogin = false
                this.videoArr = []
                this.starList = []
            }
        },
        gotoLogin() {
            this.$router.push({
                name: "UserLogin"
            })
        }
    },
    async mounted() {
        var resOfCheckLoginStatus = await request({
            url: "/api/user/isLogin",
            method: "GET"
        });
        this.isLogin = resOfCheckLoginStatus.data.data
        this.getLanguage()
        if (this.isLogin == false) {
            return;
        }
        let resOfGetMyDetailInfo = await request({
            url: "/api/user/getMyDetailInfo",
            method: "GET"
        })
        this.userInfo = resOfGetMyDetailInfo.data.data
        let resOfgetMyVideoList = await request({
            url: "/api/video/getMyVideoList",
            method: "GET"
        })
        this.videoArr = resOfgetMyVideoList.data.data
        console.log(this.videoArr);
    }
}
</script>

<style scoped>
.app {
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    background-color: #F6F6F7;
    align-items: center;
}
</style>