<template>
    <div id="app">
        <div style="display: flex;align-items: center;margin-top: 20px;">
            <svg-icon icon-class="logo" style="margin-right: 10px;width: 50px;height: 50px;"></svg-icon>
            用户注册
        </div>
        <el-form style="margin: 10px;" label-position="left" :model="ruleForm" :rules="rules" ref="ruleForm"
            label-width="80px" class="demo-ruleForm">
            <el-form-item label="用户名" prop="username">
                <el-input v-model="ruleForm.username"></el-input>
            </el-form-item>

            <el-form-item label="手机号" prop="phoneNumber">
                <el-input v-model="ruleForm.phoneNumber"></el-input>
            </el-form-item>
            <el-form-item label="验证码" prop="checkCode">
                <div style="display: flex;">
                    <el-input v-model="ruleForm.checkCode" style="margin-right: 10px;"></el-input> <el-button
                        :disabled="state" type="primary" @click="sendCheckCode"> {{ state ? "重新发送 " + stateNum : "发送验证码"
                        }}</el-button>
                </div>

            </el-form-item>
            <el-form-item label="电子邮箱" prop="email">
                <el-input v-model="ruleForm.email"></el-input>
            </el-form-item>
            <el-form-item label="公司名" prop="companyName">
                <el-input v-model="ruleForm.companyName"></el-input>
            </el-form-item>
            <el-form-item label="职位" prop="job">
                <el-input v-model="ruleForm.job"></el-input>
            </el-form-item>
        </el-form>
        <el-button style="width: 300px;margin-bottom: 20px;" type="primary" @click="UserRegister">注册</el-button>
        <el-link :underline="false" @click="goToLogin">已有帐号，直接登录</el-link>
    </div>
</template>

<script>
import request from "../axios/request";
import router from '../router/index';
export default {
    data() {
        return {
            ruleForm: {
                username: '',
                phoneNumber: '',
                checkCode: '',
                email: '',
                companyName: '',
                job: '',
            },
            state: false,
            stateNum: 60,
            rules: {
                username: [
                    { required: true, message: '请输入您的用户名', trigger: 'blur' },
                ],
                phoneNumber: [
                    { required: true, message: '请输入您的电话号码', trigger: 'blur' },
                ],
                companyName: [
                    { required: true, message: '请输入您的公司名称', trigger: 'blur' },
                ],
                job: [
                    { required: true, message: '请输入您的个人职业', trigger: 'blur' },
                ],

                checkCode: [
                    { required: true, message: '请输入短信验证码', trigger: 'blur' },
                ],
            },
            timer: null
        }
    },
    methods: {
        async UserRegister() {
            var resOfUserRegister = await request({
                url: "/api/user/save",
                method: "POST",
                data: this.ruleForm
            });
            if (resOfUserRegister.data.status == 200) {
                router.go(-1)
            }
        },
        async sendCheckCode() {
            if (!this.state) {
                // 执行网络请求
                this.state = true
                this.stateNum = 60
                this.timer = setInterval(() => {
                    this.stateNum--
                    if (this.stateNum === 0) {
                        clearInterval(this.timer)
                        this.state = false
                    }
                }, 1000)
                await request({
                    url: "/api/user/send/+86" + this.ruleForm.phoneNumber,
                    method:"GET"
                })
            }
        },
        goToLogin() {
            router.push({
                name: "UserLogin"
            })
        }
    },
    beforeDestroy() {
        console.log("销毁计时器");
        if (this.timer != null) {
            clearInterval(this.timer)
            this.state = false
        }
    }
}
</script>

<style scoped>
#app {
    width: 100%;
    position: absolute;
    height: 100%;
    left: 0;
    top: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    background-color: #F8F8F8;
    align-items: center;
    /* overflow: hidden; */
}
</style>

