<template>
    <div v-loading="loading" :element-loading-text="content9" element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0.8)" class="backClass"
        style="width: 100%;height: 100%;display: flex;flex-direction: column;align-items: center;">
        <div style="color: white;font-size: x-large;margin-top: 20px;">{{ content1 }}</div>
        <div style="color: #9DB5E4;margin-top: 10px;font-size: x-small;">{{ content2 }}</div>
        <el-upload ref="upload" class="avatar-uploader" :action="baseURL + '/api/file/upload/'" :show-file-list="false"
            :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload">
            <img v-if="imageUrl" :src="imageUrl" class="avatar">
        </el-upload>
        <div style="color: #9DB5E5;width: 70%;margin-bottom: 30px;font-size: x-small;">{{ content3 }}</div>
        <div style="width: 70%;display: flex;flex-direction: column;">
            <div style="color: #9DB5E5;margin-bottom: 10px;font-size: x-small;">
                {{ content4 }}
            </div>

            <div style="margin-bottom: 10px;width: 70%;">
                <el-radio v-model="radio" label="10" style="color: #9DB5E5;font-size: x-small;">{{content5}}</el-radio>
                <el-radio v-model="radio" label="-10" style="color: #9DB5E5;font-size: x-small;">{{content6}}</el-radio>
            </div>

        </div>


        <div class="btnClass" @click="uploadMyPic">{{ content7 }}</div>
        <div style="color: #9DB5E4;width: 70%;margin-top: 20px;font-size: x-small;">{{ content8 }}</div>
    </div>
</template>

<script>
import request from "../axios/request";
export default {
    data() {
        return {
            baseURL: null,
            imageUrl: '',
            loading: false,
            radio: "10",
            lang: null,
            content1: null,
            content2: null,
            content3: null,
            content4: null,
            content5: null,
            content6: null,
            content7: null,
            content8: null,
            content9: null
        }
    },
    methods: {
        getLanguage() {
            var language = null;
            var lang = null;
            if (navigator.appName == 'Netscape') {
                language = navigator.language;
            }
            else {
                language = navigator.browserLanguage;
            }
            if (language.indexOf('zh') > -1) {
                lang = "zh"
            } else if (language.indexOf('ja') > -1) {
                lang = "ja"
            } else {
                lang = "en"
            }
            switch (lang) {
                case 'en':
                    this.content1 = 'Upload Front-Facing Portrait'
                    this.content2 = 'Access Your Exclusive xR Virtual Shoot Video'
                    this.content3 = 'Note: For optimal matching, upload a clear, front-facing photo without a mask. Non-frontal or small-scale images may decrease matching precision.'
                    this.content4 = 'Please select the search scope'
                    this.content5 = 'Videos recorded within 10 minutes'
                    this.content6 = 'Videos that have been recorded for more than 10 minutes take longer to search.'
                    this.content7 = 'Upload Photo'
                    this.content8 = '*Photos taken are solely for facial matching and user privacy is ensured.'
                    this.content9 = 'Uploading'
                    break;
                case 'ja':
                    this.content1 = '正面顔写真をアップロード'
                    this.content2 = '限定xRバーチャル撮影ビデオを入手'
                    this.content3 = '注意：最適なマッチングのために、マスクをせずにはっきりとした正面向きの写真をアップロードしてください。非正面または小規模な画像は、マッチングの精度を低下させる可能性があります。'
                    this.content4 = '検索範囲を選択してください'
                    this.content5 = '10分以内に録画されたビデオ'
                    this.content6 = '10分以上録画が終了したビデオは、長い時間検索されます。'
                    this.content7 = '写真をアップロード'
                    this.content8 = '*撮影された写真は顔認証のみに使用され、ユーザーのプライバシーは保証されます。'
                    this.content9 = 'アップロード中'
                    break;
                case 'zh':
                    this.content1 = '上传单人正脸照片'
                    this.content2 = '获取您的专属 XR 虚拟拍摄视频'
                    this.content3 = '友情提示：为确保最佳的匹配效果，请上传您的正脸照片。拍摄时，请勿佩戴口罩。请注意，非正面角度和小尺寸人物照片可能会降低匹配的精确度。'
                    this.content4 = '请选择搜索范围'
                    this.content5 = '10分钟以内录制的视频'
                    this.content6 = '录制结束超过10分钟的视频，查找时间较长。'
                    this.content7 = '上传照片'
                    this.content8 = '* 拍摄的照片仅用于人脸匹配，并保证用户隐私安全。'
                    this.content9 = '上传中'
                    break;
            }
        },
        uploadMyPic() {
            this.$refs['upload'].$children[0].$refs.input.click();
        },
        handleAvatarSuccess(res, file) {
            this.loading = false
            this.$router.push({
                name: 'VideoListCom',
                params: {
                    content: res.data + "#" + this.radio,
                },
            });
        },
        beforeAvatarUpload(file) {

            const isJPG = file.type === 'image/jpeg' || file.type === 'image/png';
            const selected = this.radio != null
            if (!selected) {
                this.$message.error('请选择搜索范围');
            }
            if (!isJPG) {
                this.$message.error('上传头像图片只能是 图片 格式!');
            }
            if (isJPG && selected) {
                this.loading = true
            }
            return isJPG && selected;
        },
    },
    created() {
        this.getLanguage()
        this.baseURL = request.defaults.baseURL;
        console.log(this.baseURL);
    }
}
</script>

<style>
.el-radio__label {

text-overflow: ellipsis;

white-space: normal;

line-height: 18px;

vertical-align: middle;

display: inline-block;

}

.btnClass {
    height: 50px;
    width: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: url("../assets/button.png") center center no-repeat;
    background-size: contain;
    color: white;
    font-size: larger;
    font-weight: 700;
}

.backClass {
    background: url('../assets/background.png');
}

.avatar-uploader .el-upload {
    border: 1px dashed #2E57C8;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    background-color: white;
    width: 300px;
    height: 300px;
    background: url("../assets/round.png") 200% center / contain no-repeat, url("../assets/head.png") center center / 25% 30% no-repeat;
    /* background-size: contain; */
    /* box-shadow: 0 10px 5px 0 rgba(237, 239, 241, 0.35); */
}

.avatar-uploader .el-upload:hover {
    border-color: #409EFF;
}

.avatar {
    width: 300px;
    height: 300px;
    display: block;
}
</style>