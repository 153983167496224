<template>
    <div id="app" class="backClass" v-loading="loading" :element-loading-text="content1"
        style="width: 100%;height: 100%;display: flex;flex-direction: column;align-items: center;overflow: auto;">
        <!-- <transition name="el-zoom-in-center"> -->
        <div v-for="item, index in videoList" :key="index"
            style="width: 90%;margin-top: 10px;display: flex;flex-direction: column;align-items: center;justify-content: space-between;margin-top: 10px;">
            <video webkit-playsinline="true" x-webkit-airplay="true" playsinline="true" x5-video-player-type="h5"
                x5-video-orientation="h5" x5-video-player-fullscreen="true" preload="auto" :poster="item.videoPoster"
                :src="item.videoUrl" style="width: 300px;height: 200px;background-color: black;" controls
                @play="videoPlay" controlslist="nodownload">
                <source :src="item.videoUrl" type="video/mp4">
            </video>
            <el-button type="primary" style="margin-top: 10px;" @click="downLoadCurVideo(item.videoUrl)">{{ content2
                }}</el-button>
        </div>
        <div v-show="!loading && finish == false" style="margin: 20px 0;color: white;">{{ content3 }}</div>
        <!-- 没有找到？点击重新匹配 -->
        <el-button v-show="!loading && finish == true" style="margin: 20px 0;" @click="backToUpload">{{ content4
            }}</el-button>
    </div>
</template>

<script>
import request from "../axios/request";
export default {
    data() {
        return {
            socket: null,
            videoList: [
                // {
                //     videoUrl:'http://120.234.47.242:8022/20240222170917.mp4',
                //     videoPoster:'http://120.234.47.242:9000/xrproject/1709257667911.png'
                // }
            ],
            PayArray: [
                {
                    icon: 'weixin',
                    content: '微信支付',
                    label: 1
                }
            ],
            loading: true,
            drawer: false,
            radio: 1,
            baseURL: null,
            finish: false,
            imageUrl: null,
            imageId: null,
            timeLimit: null,
            content1: null,
            content2: null,
            content3: null,
            content4: null
        }
    },
    methods: {
        getLanguage() {
            var language = null;
            var lang = null;
            if (navigator.appName == 'Netscape') {
                language = navigator.language;
            }
            else {
                language = navigator.browserLanguage;
            }
            if (language.indexOf('zh') > -1) {
                lang = "zh"
            } else if (language.indexOf('ja') > -1) {
                lang = "ja"
            } else {
                lang = "en"
            }
            switch (lang) {
                case 'en':
                    this.content1 = "System matching in progress"
                    this.content2 = "Download this video"
                    this.content3 = "Continuously matching for you"
                    this.content4 = "Not found? Click to re-upload a frontal photo for a rematch."
                    break;
                case 'ja':
                    this.content1 = "システムマッチング中"
                    this.content2 = "このビデオをダウンロード"
                    this.content3 = "継続的にマッチさせる"
                    this.content4 = "見つからない？正面颜写真を再アップで再マッチ。"
                    break;
                case 'zh':
                    this.content1 = "系统匹配中"
                    this.content2 = "下载本视频"
                    this.content3 = "持续为您匹配中"
                    this.content4 = "没有找到？点击重新匹配"
                    break;
            }
        },
        backToUpload() {
            this.$router.push({
                name: 'indexCom'
            });
        },
        initWs(imageId) {

            if (typeof (WebSocket) === "undefined") {
                alert("您的浏览器不支持socket")
            } else {
                var wsUrl = this.baseURL.replace("http://", "")
                // 实例化socket 111是固定的用户id,正式环境直接获取当前登录用户id
                this.socket = new WebSocket("ws:" + wsUrl + "//wsServer/" + imageId)
                this.global.setWs(this.socket)
                var that = this
                // 监听socket连接
                this.socket.onopen = () => {
                    console.log("socket连接成功")
                    request({
                        url: "/api/file/searchForVideo",
                        method: "POST",
                        data: {
                            imageUrl: that.imageUrl,
                            imageId: that.imageId,
                            timeLimit: that.timeLimit
                        }
                    });
                }
                // 监听socket错误信息
                this.socket.onerror = () => {
                    console.error("连接错误")
                }
                //监听socket消息
                this.socket.onmessage = (msg) => {
                    var resData = msg.data.split(":\"")[1]
                    resData = resData.replace("\"}", "")
                    var resType = resData.split("+")[0]
                    var replyData = resData.split("+")[1]
                    if (resType == 1) {
                        this.loading = false
                        // 视频地址
                        if (replyData.search('Done') != -1) {
                            this.closeWebsocket()
                            // alert("ship匹配完毕");
                            this.finish = true
                        } else {
                            // console.log(replyData);
                            var videoUrl = replyData.split("$")[0]
                            var videoPoster = replyData.split("$")[1]
                            this.videoList.push({
                                videoUrl: videoUrl,
                                videoPoster: videoPoster
                            })
                            //  console.log(video);
                        }
                    }
                    // console.log("当前videoList："+this.videoList);
                }
                // 监听socket关闭信息
                this.socket.onclose = (e) => {
                    console.log("socket已经关闭")

                }
            }
        },
        videoPlay() {

        },
        async downLoadCurVideo(item) {
            console.log(item);
            var isOpenPayment = await request({
                url: "/api/user/isOpenPaymentChannel",
                method: "GET"
            })
            if (isOpenPayment.data.data == true) {
                sessionStorage.setItem("videoUrl", item)
                this.$router.push({
                    name: 'DownloadPage'
                })
            } else {
                var x = new XMLHttpRequest()
                x.open('GET', item, true)
                x.responseType = 'blob'
                x.onload = function () {
                    const url = window.URL.createObjectURL(x.response)
                    const eleLink = document.createElement('a')
                    eleLink.href = url
                    eleLink.download = '4k.mp4'
                    eleLink.style.display = 'none'
                    document.body.appendChild(eleLink)
                    eleLink.click()
                    document.body.removeChild(eleLink)
                }
                x.send()
            }

        },
        changePayWay() {

        },
        closeWebsocket() {
            if (this.socket != null) {
                this.socket.close()
                this.socket = null
                // console.log();
            }
        }
    },
    async mounted() {
        this.getLanguage()
        this.baseURL = request.defaults.baseURL;
        var imageUrlAndImageId = this.$route.params.content
        this.imageUrl = imageUrlAndImageId.split("#")[0]
        this.imageId = imageUrlAndImageId.split("#")[1]
        this.timeLimit = imageUrlAndImageId.split("#")[2]
        var resOfGettingUserId = await request({
            url: "/api/user/getUserId",
            method: "GET"
        });
        if (resOfGettingUserId.data.data != null) {
            imageId = resOfGettingUserId.data.data
        }

        this.initWs(this.imageId)
    },
    beforeDestroy() {
        // console.log("执行摧毁钩子");
        // this.closeWebsocket()
    }
}
</script>

<style scoped>
.backClass {
    background: url('../assets/background.png');
    background-size: cover;
}

#app {
    width: 100%;
    position: absolute;
    height: 100%;
    left: 0;
    top: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    background-color: aqua;
    /* overflow: hidden; */
}
</style>