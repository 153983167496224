<template>
    <div id="app">
        <div style="width: 90%;margin: 20px 10px;flex: 8;display: flex;flex-direction: column;align-items: center;">
            <div style="width: 100%;display: flex;justify-content: space-between;">
                <el-checkbox-group v-model="parameterArr" @change="handleChange">
                    <el-checkbox-button style="color: white;" :label="content1">
                    </el-checkbox-button>
                    <el-checkbox-button style="color: white;" label="4K 19.99">
                    </el-checkbox-button>
                </el-checkbox-group>
                <el-link v-if="!isLogin" type="primary" @click="gotoLogin">{{ content2
                    }}</el-link>
            </div>

            <div style="width: 100%;flex: 1;display: flex;flex-direction: column;">
                <div v-for="item, index in parameterArr" :key="index"
                    style="width: 100%;display: flex;align-items: center;padding: 10px 0;margin: 10px 0;background-color: white;">
                    <div style="flex: 8;margin-left: 10px;">
                        {{ item.split(" ")[0] }}
                    </div>
                    <div style="flex: 2;text-align: end;margin-right: 10px;color: red;">
                        {{ item.split(" ")[1] }}
                    </div>
                </div>
            </div>
        </div>

        <div
            style="width: 90%;display: flex;border: solid 1px black;padding: 10px;background-color: white;border-radius: 10px;margin-bottom: 20px;">
            <div style="width: 80%;display: flex;justify-content: end;align-items: end;padding: 10px;">{{ content3 }}：
                <div style="color: red;">
                    {{ allTotal / 100 }}
                </div>

            </div>
            <el-button style="flex: 1;" type="primary" @click="payForVideo">{{ content4 }}</el-button>
        </div>
    </div>
</template>

<script>
import request from "../axios/request";
export default {
    data() {
        return {
            parameterArr: [],
            allTotal: 0,
            isLogin: false,
            lang: null,
            content1: null,
            content2: null,
            content3: null,
            content4: null
        }
    },
    methods: {
        getLanguage() {
            var language = null;
            var lang = null;
            if (navigator.appName == 'Netscape') {
                language = navigator.language;
            }
            else {
                language = navigator.browserLanguage;
            }
            if (language.indexOf('zh') > -1) {
                lang = "zh"
            } else if (language.indexOf('ja') > -1) {
                lang = "ja"
            } else {
                lang = "en"
            }
            switch (lang) {
                case 'en':
                    if (this.isLogin) {
                        this.content1 = 'HD 0.00'
                    } else {
                        this.content1 = 'HD(Free download after login) 9.99'
                    }
                    this.content2 = 'login'
                    this.content3 = 'total'
                    this.content4 = 'Confirm Payment'
                    break;
                case 'ja':
                    if (this.isLogin) {
                        this.content1 = 'HD 0.00'
                    } else {
                        this.content1 = 'HD(ログイン後無料ダウンロード) 9.99'
                    }
                    this.content2 = 'ログイン'
                    this.content3 = '合計費用'
                    this.content4 = '支払いの確認'
                    break;
                case 'zh':
                    if (this.isLogin) {
                        this.content1 = '高清 0.00'
                    } else {
                        this.content1 = '高清(登陆后免费下载) 9.99'
                    }
                    this.content2 = '去登陆'
                    this.content3 = '总计费用'
                    this.content4 = '确认支付'
                    break;
            }
        },
        handleChange() {
            sessionStorage.removeItem("videoParameter")
            var total = 0
            var aimVideo = "";
            for (let index = 0; index < this.parameterArr.length; index++) {
                const element = this.parameterArr[index];
                aimVideo = aimVideo + element.split(" ")[0] + "-"
                var price = Number(element.split(" ")[1]) * 100
                total += price
            }
            sessionStorage.setItem("videoParameter", aimVideo)
            this.allTotal = total
        },
        async payForVideo() {
            if (sessionStorage.getItem("videoParameter") == null) {

            }
            if (this.allTotal <= 0) {
                var resOfOrderForFree = await request({
                    url: '/api/wechatPay/orderForFree?videoUrl=' + encodeURIComponent(sessionStorage.getItem("videoUrl")) + "&aimVideoParameter=" + sessionStorage.getItem("videoParameter"),
                    method: 'POST'
                })
                if (resOfOrderForFree.data.status == 200) {
                    this.$router.push({
                        name: 'PaySuccessCom',
                        params: {
                            noneStr: resOfOrderForFree.data.data
                        }
                    })
                }
            } else {
                window.location.href = "https://open.weixin.qq.com/connect/oauth2/authorize?" +
                    "appid=wxea76655bc3249de7&" +
                    "redirect_uri=http%3A%2F%2Fxrpay.sz-ipc.com%2F%23%2FPayCom&" +
                    "response_type=code&" +
                    "scope=snsapi_userinfo&" +
                    "state=" + this.allTotal + "#wechat_redirect"
            }
        },
        gotoLogin() {
            this.$router.push({
                name: 'UserLogin'
            })
        }
    },
    async mounted() {

        // 判断当前用户是否已经登录
        var resOfCheckLoginStatus = await request({
            url: "/api/user/isLogin",
            method: "GET"
        });
        this.isLogin = resOfCheckLoginStatus.data.data
        this.getLanguage()
        // alert(this.isLogin)
    }
}
</script>

<style scoped>
#app {
    width: 100%;
    position: absolute;
    height: 100%;
    left: 0;
    top: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    background-color: #F8F8F8;
    align-items: center;
}
</style>