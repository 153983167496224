<template>
    <div id="app">
        <div id="tip" v-show="androidAndWx"></div>
        <svg-icon icon-class="success"
            style="width: 100px;height: 100px;margin-top: 200px;margin-bottom: 20px;"></svg-icon>
        <div style="color:#597CDE;font-weight: 700;margin-bottom: 20px;">{{ content1 }}</div>
        <!-- <div style="color:white;font-size: small;margin-bottom: 20px;">视频已开启自动下载，若无反应可点击下方 下载视频 按钮</div> -->
        <div v-for="item, index in realUrl" :key="index"
            style="display: flex;align-items: center;width: 50%;justify-content: space-between;margin-bottom: 10px;">
            <div style="color: white;">{{ item.search("videosSD") == -1 ? "4K" : content2 }}</div>
            <el-button @click="downloadAfterPay(item, item.search('videosSD') == -1 ? '4K.mp4' : content3)"
                type="primary">{{ content4 }}</el-button>
        </div>

    </div>
</template>

<script>
import request from "../axios/request";
export default {
    data() {
        return {
            androidAndWx: false,
            realUrl: [],
            lang: null,
            content1: null,
            content2: null,
            content3: null,
            content4: null
        }
    },
    methods: {
        getLanguage() {
            var language = null;
            var lang = null;
            if (navigator.appName == 'Netscape') {
                language = navigator.language;
            }
            else {
                language = navigator.browserLanguage;
            }
            if (language.indexOf('zh') > -1) {
                lang = "zh"
            } else if (language.indexOf('ja') > -1) {
                lang = "ja"
            } else {
                lang = "en"
            }
            switch (lang) {
                case 'en':
                    this.content1 = "Congratulations, payment successful"
                    this.content2 = "HD"
                    this.content3 = 'HD.mp4'
                    this.content4 = 'download video'
                    break;
                case 'ja':
                    this.content1 = "おめでとう、支払い成功"
                    this.content2 = "HD"
                    this.content3 = 'HD.mp4'
                    this.content4 = 'ビデオのダウンロード'
                    break;
                case 'zh':
                    this.content1 = "恭喜你，支付成功"
                    this.content2 = "高清"
                    this.content3 = '高清.mp4'
                    this.content4 = '下载视频'
                    break;
            }
        },
        iswx() {
            var ua = navigator.userAgent.toLowerCase();
            var isWeixin = ua.indexOf('micromessenger') != -1;
            if (isWeixin) {
                return true;
            } else {
                return false;
            }
        },
        getQueryString(name) {
            var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
            var r = window.location.search.substr(1).match(reg);
            if (r != null) {
                return unescape(r[2]);
            }
            return null;
        },
        async downloadAfterPay(url, name) {
            var x = new XMLHttpRequest()
            x.open('GET', url, true)
            x.responseType = 'blob'
            x.onload = function () {
                const url = window.URL.createObjectURL(x.response)
                const eleLink = document.createElement('a')
                eleLink.href = url
                eleLink.download = name
                eleLink.style.display = 'none'
                document.body.appendChild(eleLink)
                eleLink.click()
                document.body.removeChild(eleLink)
            }
            x.send()
        },

    },
    async mounted() {
        this.getLanguage()
        var isWx = this.iswx()
        if (isWx) {
            // 提示跳转页面
            this.androidAndWx = true
        } else {
            var url = this.$route.params.noneStr
            var resOfGetRealUrl = await request({
                url: "/api/video/getRealUrl?noneStr=" + url,
                method: "POST"
            });
            if (resOfGetRealUrl.data.status == 200) {
                this.realUrl = resOfGetRealUrl.data.data
            }
        }

    }
}
</script>

<style scoped>
#app {
    width: 100%;
    position: absolute;
    height: 100%;
    left: 0;
    top: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    background-color: #F8F8F8;
    align-items: center;
    background: url("../assets/background.png") center center no-repeat;
    /* overflow: hidden; */
}

#tip {
    width: 100%;
    position: absolute;
    height: 100%;
    left: 0;
    top: 0;
    padding: 0;
    background: url("../assets/android.png") center center / contain no-repeat;
    z-index: 10;
    background-color: rgb(64, 62, 62);
    /* opacity: 0.9; */
}
</style>
