<template>
    <div style="display: flex;flex-direction: column;align-items: center;">
        <!-- <div>微信支付</div> -->
        <div>{{ String(result.timeStamp) }} </div>
        <div>{{ result.appId }}</div>
        <div>{{ result.nonceStr }} </div>
        <div>{{ result.package }} </div>
        <div>{{ result.signType }} </div>
        <div>{{ result.paySign }}</div>
    </div>
</template>

<script>
import request from "../axios/request";


export default {
    data() {
        return {
            result: null,
            nonceStr: null
        }
    },
    methods: {
        getQueryString(name) {
            var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
            var r = window.location.search.substr(1).match(reg);
            if (r != null) {
                return unescape(r[2]);
            }
            return null;
        },
        onBridgeReady(data) {
            var that = this
            WeixinJSBridge.invoke('getBrandWCPayRequest', {
                "appId": data.appId,     //公众号ID，由商户传入     
                "timeStamp": String(data.timeStamp),     //时间戳，自1970年以来的秒数     
                "nonceStr": data.nonceStr,      //随机串     
                "package": data.package,
                "signType": data.signType,     //微信签名方式：     
                "paySign": data.paySign  //微信签名 
            },
                function (res) {
                    if (res.err_msg == "get_brand_wcpay_request:ok") {
                        that.$router.push({
                            name: 'PaySuccessCom',
                            params: {
                                noneStr: that.nonceStr
                            }
                        })
                    } else if (res.err_msg == "get_brand_wcpay_request:cancel") {
                        alert("支付取消")
                        that.$router.go(-1)
                    } else {
                        alert("支付失败")
                        //支付失败
                        that.$router.go(-1)
                    }

                });

        }

    },
    async mounted() {
        
        var resofJSAPI = await request({
            url: "/api/wechatPay/jsapi?code=" + this.getQueryString("code") + "&videoUrl=" + encodeURIComponent(sessionStorage.getItem("videoUrl"))+"&total="+this.getQueryString("state")+"&aimVideoParameter="+sessionStorage.getItem("videoParameter"),
            method: "POST"
        })
        if (resofJSAPI.data.data.package.search('prepay_id=null') == -1) {
            this.nonceStr = resofJSAPI.data.data.nonceStr
            this.onBridgeReady(resofJSAPI.data.data)
        } else {
            this.$router.go(-1)
        }
    }
}
</script>

<style></style>