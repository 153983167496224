<template>
  <div id="app">
    <div style="flex: 1;width: 100%;">
      <router-view></router-view>
    </div>

    <div v-if="tabArr.length>0" style="height: 80px;background-color: white;display: flex;width: 100%;">
      <div v-for="item, index in tabArr" :key="index"
        style="flex: 1;display:flex;flex-direction: column;justify-content: center;align-items: center;margin-bottom: 10px;"
        @click="navigateTab(item.componentName)">
        <svg-icon :icon-class="item.componentName == activeTab ? item.aniName + ' active' : item.aniName"
          style="width: 50px;height: 50px;"></svg-icon>
        <div :style="{ color: item.componentName == activeTab ? '#5996F0' : '#ACACAC' }">
          {{ item.name }}
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import request from "../axios/request";
export default {
  data() {
    return {
      tabArr: [
        // {
        //   name: '首页',
        //   componentName: 'indexCom',
        //   aniName: 'indexAni'
        // }, {
        //   name: '咨询',
        //   componentName: 'InfoCom',
        //   aniName: 'information'
        // }, {
        //   name: '我的',
        //   componentName: 'MineInfo',
        //   aniName: 'mine'
        // }
      ],
      activeTab: 'indexCom'
    }
  },
  methods: {
    async getTabBarItem() {
      var resOfGetTabBarItem = await request({
        url: "/api/tabBar/getAllTabBarItem",
        method: "GET"
      })
      if (resOfGetTabBarItem.data.status == 200) {
        this.tabArr = resOfGetTabBarItem.data.data
      }
    },
    navigateTab(componentName) {
      // console.log(componentName);
      this.activeTab = componentName
      console.log(this.activeTab);
      this.$router.push({
        name: componentName
      });
    }
  },
  mounted() {
    this.getTabBarItem()
    var language = null;
    var lang = null;
    if (navigator.appName == 'Netscape') {
      language = navigator.language;
    }
    else {
      language = navigator.browserLanguage;
    }
    if (language.indexOf('zh') > -1) {
      lang = "zh"
    } else if (language.indexOf('ja') > -1) {
      lang = "ja"
    } else {
      lang = "en"
    }
    switch (lang) {
      case 'en':
        this.tabArr[0].name = 'home'
        this.tabArr[1].name = 'news'
        this.tabArr[2].name = 'me'
        break;
      case 'ja':
        this.tabArr[0].name = 'トップページ'
        this.tabArr[1].name = 'ニュース'
        this.tabArr[2].name = 'マイ'
        break;
      case 'zh':
        this.tabArr[0].name = '首页'
        this.tabArr[1].name = '咨询'
        this.tabArr[2].name = '我的'
        break;
    }
  }
}
</script>

<style scoped>
#app {
  width: 100%;
  position: absolute;
  height: 100%;
  left: 0;
  top: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  background-color: white;
  /* overflow: hidden; */
}
</style>